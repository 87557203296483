import React from "react";
import { graphql } from "gatsby";

import { Grid, Panel } from "react-bootstrap";

import { Helmet } from "react-helmet";

import style from "./styles";
import supify from "./supify";

const PageRoot = ({
	classes,
	data: {
		contentfulPage: {
			title,
			content: {
				childMarkdownRemark: { html: content }
			}
		}
	}
}) => (
	<Grid style={{ paddingTop: 51 }}>
		<Panel>
			<Helmet>
				<title>{title}</title>
			</Helmet>

			<h1>{title}</h1>

			{content ? (
				<div
					className={classes.container}
					dangerouslySetInnerHTML={{ __html: supifyReg(content) }}
				/>
			) : null}
		</Panel>
	</Grid>
);

const supifyReg = str => supify(str, ["®"]);

export default style(PageRoot);

export const pageQuery = graphql`
	query PageBySlug($slug: String!) {
		contentfulPage(slug: { eq: $slug }, pageType: { ne: "FAQ" }) {
			title
			content {
				childMarkdownRemark {
					html
				}
			}
		}
	}
`;
